import React from 'react';
import styled from 'styled-components';
import { color } from '../constants/color';
import logo from '../assets/dinoparks-white.svg';
import rootLogo from '../assets/root-logo.svg';

export const Footer = () => (
  <FooterContainer>
    <img src={logo} alt='' />
    <PoweredBy>
      <span style={{ color: 'white' }}>Powered by</span>
      <img src={rootLogo} alt='' />
    </PoweredBy>
  </FooterContainer>
);

const FooterContainer = styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  background-color: #240e8b;
  display: flex;
  align-items: center;

  img {
    margin-left: 40px;
    max-height: 80%;
  }

  @media (max-width: 768px) {
    height: 50px;
    align-items: left;
    justify-content: left;
  } ;
`;

const PoweredBy = styled.div`
  font-size: 9px;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  right: 2%;
  img {
    margin-left: 3px;
    padding-bottom: 1px;
  }
`;
