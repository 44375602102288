import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import backgroundImage from '../assets/dinoparks-home.svg';
import backgroundImageTall from '../assets/dinoparks-home-tall.svg';

import backgroundImageMobile from '../assets/dinoparks-home-mobile.svg';

import { devices } from '../constants/devices';

export const Home = () => {
  return (
    <MainContainer>
      <MainSection>
        <TextContainer>
          <div>
            <h1>You must be from the future.</h1>
            <h1>Welcome to the past...</h1>
            <p className='main-text'>
              Visit the safest dinosaur park in the world and observe your favourite giants in their natural habitat.
            </p>
          </div>
        </TextContainer>
        <GetDinosureCard>
          <span className='new'>NEW!</span>
          <h1>Dinosure</h1>
          <p className='sub-heading'>Your protection from the Triassic Tyranny: a world 1st!</p>
          <Link to='/dinosure'>
            <Button className='btn-primary'>Get Dinosure</Button>
          </Link>
        </GetDinosureCard>
      </MainSection>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  height: 90vh;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #f2f9ff 12%, #f2f5ff 100%); ;
`;

const MainSection = styled.section`
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right;
  background-size: 65%;

  @media (min-width: 1900px) {
    background-size: 60%;
  }

  @media (max-width: 1350px) {
    background-size: 70%;
  }

  @media (max-width: 1251px) {
    background-size: cover;
  }

  @media (max-width: 925px) {
    background-size: cover;
    background-position: bottom right;
    background-image: url(${backgroundImageTall});

  }

  @media ${devices.mobileL} {
    background-position: 0% 92%;
    background-size: 100%;
    background-image: url(${backgroundImageMobile});
  },
`;

const TextContainer = styled.div`
  margin: 4% 10% 1%;
  color: #240E8B;
  width: 35%;

  div {
    margin-bottom: 40px;
  }

  h1 {
    font-size: 30px;
    font-weight: 900;
    font-family: 'Lato', sans-serif;
    margin-bottom: 0;
  }

  h2 {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 0px;
  }

  .main-text {
    font-size: 20px;
    margin-top: 20px;
  }

  .secondary-text {
    font-size: 22px;
  }

  @media ${devices.tablet} {
    width: 70%;
  }


  @media ${devices.mobileL} {
    width: 90%;
    margin: 40px auto 0;

    h1 {
      font-size: 25px;
    }

    .main-text {
      font-size: 15px;
      margin-top: 20px;
    }
  }


  @media ${devices.mobileM} {
      margin-top: 3%;
      font-size: 20px;

      h1 {
        font-size: 20px;
      }

      .main-text {
        font-size: 12px;
        margin-top: 10px;
      }

      div {
        margin-bottom: 10px;
      }

  }
}
`;

const GetDinosureCard = styled.div`
  color: #240e8b;
  border-radius: 18px;
  box-shadow: 0px 4px 16px rgba(55, 81, 255, 0.25);
  padding: 26px 35px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  line-height: 36px;
  width: 100%;
  max-width: 500px;
  margin: 0 10%;
  background-color: #ffffff;

  .new {
    color: #faa321;
    font-weight: 600;
  }

  .sub-heading {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 20px;
  }

  @media ${devices.mobileL} {
    padding: 15px 20px;
    font-size: 15px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 20px;
    font-weight: 900;
  }

  .sub-heading {
    font-size: 15px;
    line-height: 25px;
  }

  button {
    font-size: 16px;
    height: 50px;
    margin-bottom: 10px;
  }

  @media ${devices.mobileM} {
    max-width: 90%;
    margin-bottom: 0px;

    h1 {
      font-size: 15px;
      font-weight: bold;
    }

    .sub-heading {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 5px;
    }

    button {
      font-size: 10px;
      height: 35px;
      margin-bottom: 0px;
    }
  }
`;
