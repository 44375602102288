import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Dinosure } from './routes/dinosure';
import { DinosureConfirm } from './routes/dinosure-confirm';
import { Home } from './routes/home';
import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}>
        <Route path='/' element={<Home />}></Route>
        <Route path='/dinosure' element={<Dinosure />}></Route>
        <Route path='/issue-policy/1/confirmation' element={<DinosureConfirm />}></Route>
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root'),
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
